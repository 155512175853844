import React, { Component } from 'react';
// import { Link } from 'react-router-dom'
import Logo from '../components/logo/logo'
import ResponsiveAlignment from '../components/responsivealignment/responsivealignment';
import ExportCode from '../components/exportcode/exportcode';
import EditableProperties from '../components/editableproperties/editableproperties';
import LivePreview from '../components/livepreview/livepreview';
import LivePreviewMobile from '../components/livepreviewmobile/livepreviewmobile';
import Footer from '../components/footer/footer';
import FooterMobile from '../components/footermobile/footermobile';
import './Index.css';
import QuickExport from './assets/quick-export.gif';
// import Responsive from './assets/quick-alignment.gif';

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        // <Link to='/contact'><img src={logo} className="App-logo" alt="logo" /></Link>
        // <Link to='/Profile'><img src={logo} className="App-logo" alt="logo" /></Link>

        return (
            <div className="index">
                <div className="index-promo">
                    <div className="index-logo">
                        <Logo style={{ position: 'relative' }} />
                    </div>
                    <div className="index-quick-export">
                        <iframe width="1247.99px" height="771.99px" src="https://www.youtube.com/embed/vEyGiv6EXNo" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </div>
                <div className="flame-top-container">
                    <div className="flame-top">
                        <ResponsiveAlignment style={{ position: 'relative' }} />
                    </div>
                </div>
                <div className="flame-left-container">
                    <div className="flame-left">
                        <ExportCode style={{ position: 'relative' }} />
                    </div>
                </div>
                <div className="flame-bottom-container">
                    <div className="flame-bottom">
                        <EditableProperties style={{ position: 'relative' }} />
                    </div>
                </div>
                <div className="index-live-preview-container">
                    <div className="index-live-preview">
                        <LivePreview style={{ position: 'relative' }} />
                    </div>
                </div>
                <div className="index-live-preview-mobile-container">
                    <div className="index-live-preview-mobile">
                        <LivePreviewMobile style={{ position: 'relative' }} />
                    </div>
                </div>
                <div className="index-footer-container">
                    <div className="index-footer">
                        <Footer style={{ position: 'relative' }} />
                    </div>
                </div>
                <div className="index-footer-mobile-container">
                    <div className="index-footer-mobile">
                        <FooterMobile style={{ position: 'relative' }} />
                    </div>
                </div>
            </div>
        );
    }
}

export default Index;
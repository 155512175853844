import React, { Component } from 'react';
// import { Link } from 'react-router-dom'
import DownloadPlugin from '../components/downloadplugin/downloadplugin'
import DownloadPreview from '../components/downloadpreview/downloadpreview'
import FooterMobile from '../components/footermobile/footermobile';
import './Download.css';

class Download extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        // <Link to='/contact'><img src={logo} className="App-logo" alt="logo" /></Link>
        // <Link to='/Profile'><img src={logo} className="App-logo" alt="logo" /></Link>
        // <DownloadPlugin style={{ position: 'relative' }} />
        //         <DownloadPreview />
        return (
            <div className="download">
                <div className="download-content">
                    <div className="download-item"><DownloadPlugin style={{ position: 'relative' }} /></div>
                    <div className="download-item"><DownloadPreview style={{ position: 'relative' }} /></div>
                </div>
                <div className="download-footer-mobile-container">
                    <div className="download-footer-mobile">
                        <FooterMobile style={{ position: 'relative' }} />
                    </div>
                </div>
            </div>
        );
    }
}

export default Download;
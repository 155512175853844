import React, { Component } from 'react';
import Amplify, { API, Logger } from 'aws-amplify';
import awsconfig from '../aws-exports';
import { withAuthenticator, Authenticator, S3Album } from 'aws-amplify-react'; // or 'aws-amplify-react-native';
import { Link } from 'react-router-dom'
import logo from '../logo.svg'
import './Profile.css';

// window.LOG_LEVEL = 'DEBUG';

// const logger = new Logger('foo', 'INFO');
// logger.debug('callback data');

Amplify.configure(awsconfig);
// Storage.configure({level: 'private'});

class Profile extends Component {

    uploadFile = (evt) => {
        const file = evt.target.files[0];
        const name = file.name;

        Storage
            .put(name, file)
            .then(() => {
                this.setState({ file: name });
            })
    }

    componentDidMount() {
        // Analytics.record('Amplify_CLI');
    }

    post = async () => {
        console.log('calling api');
        // const response = await API.post('engine', '/code', {
        //     body: {
        //         id: '1',
        //         name: 'hello amplify 55555!'
        //     }
        // });
        // const response = await API.post('component', '/items', {
        //     body: {
        //         id: '1',
        //         name: 'hello amplify 55555!'
        //     }
        // });
        const response = await API.post('profile', '/user', {
            body: {
                id: 'teste',
                version: '2',
                appLanguage: 'pt',
                systemLocale: 'br',
            }
        });
        alert(JSON.stringify(response, null, 2));
    }
    get = async () => {
        console.log('calling api');
        // const response = await API.get('component', '/items/object/1');
        const response = await API.get('profile', '/user/object/teste');
        // const response = await API.get('conversor', '/to-code');
        alert(JSON.stringify(response, null, 2));
    }
    list = async () => {
        console.log('calling api');
        // const response = await API.get('component', '/items/1');
        const response = await API.get('profile', '/user');
        alert(JSON.stringify(response, null, 2));
        // console.log('--------------- Amplify ---------------', Amplify.AWS.config);
    }

    render() {
        return (
            <div className="Profile">
                <Link to='/contact'><img src={logo} className="App-logo" alt="logo" /></Link>
                <Authenticator usernameAttributes='email'>
                    <a href="live-preview/current/mac/Fireblade.app">Download Here</a>
                    <p>
                        Pick a file</p>
                    <input type="file" onChange={this.uploadFile} />
                    <button onClick={this.post}>POST</button>
                    <button onClick={this.get}>GET</button>
                    <button onClick={this.list}>LIST</button>

                    <S3Album level="private" path='' />
                </Authenticator>
            </div>
        );
    }
}

const signUpConfig = {
    header: 'Sign Up',
    hideAllDefaults: true,
    defaultCountryCode: '1',
    signUpFields: [
        {
            label: "First Name",
            key: "name",
            required: true,
            displayOrder: 1
        }, {
            label: "Last Name",
            key: "family_name",
            required: true,
            displayOrder: 2
        }, {
            label: 'Email',
            key: 'email',
            required: true,
            displayOrder: 3,
            type: 'string'
        }, {
            label: 'Password',
            key: 'password',
            required: true,
            displayOrder: 4,
            type: 'password'
        }
    ]
};

export default withAuthenticator(Profile, { signUpConfig, usernameAttributes: 'email' });
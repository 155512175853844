// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_content_delivery_bucket": "fireblade-prod",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "http://fireblade-prod.s3-website-us-east-1.amazonaws.com",
    "aws_cognito_identity_pool_id": "us-east-1:be197b59-ef3e-425d-a8f1-dc6ba4c2394c",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_USGlgyzUk",
    "aws_user_pools_web_client_id": "3nq7m6qeu293rff2c6gnm1vg3i",
    "oauth": {},
    "aws_user_files_s3_bucket": "fbstorage-prod",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "components-prod",
            "region": "us-east-1"
        },
        {
            "tableName": "users-prod",
            "region": "us-east-1"
        },
        {
            "tableName": "activity-prod",
            "region": "us-east-1"
        }
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "engine",
            "endpoint": "https://hxpafc0sih.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "profile",
            "endpoint": "https://ezr4zv1ee5.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        }
    ],
    "aws_mobile_analytics_app_id": "1d679ef0c76f493bbc998190b88b8c4d",
    "aws_mobile_analytics_app_region": "us-east-1"
};


export default awsmobile;

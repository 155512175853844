import React, {Component} from 'react';
import {Link} from 'react-router-dom'
import logo from '../logo.svg'
import './Contact.css';

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <div className="Contact">
                <Link to='/'><img src={logo} className="App-logo" alt="logo"/></Link>
            </div>
        );
    }
}

export default Contact;
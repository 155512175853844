import React, { Component } from 'react';
import Amplify, { API, Logger } from 'aws-amplify';
import awsconfig from '../aws-exports';
import { withAuthenticator, Authenticator } from 'aws-amplify-react';
import { Link } from 'react-router-dom';
import './Admin.css';

window.LOG_LEVEL = 'DEBUG';

const logger = new Logger('foo', 'INFO');
logger.debug('callback data');

Amplify.configure(awsconfig);

class Admin extends Component {

  componentDidMount() {
    
  }

  render() {
    return (
      <div className="profile">
        <Authenticator usernameAttributes='email'>
          <Link to='/contact'>CONTACT</Link>
          <div>ADMIN</div>
        </Authenticator>
      </div>
    );
  }
}

const signUpConfig = {
  header: 'Sign Up',
  hideAllDefaults: true,
  defaultCountryCode: '1',
  signUpFields: [
    {
      label: "First Name",
      key: "name",
      required: true,
      displayOrder: 1
    }, {
      label: "Last Name",
      key: "family_name",
      required: true,
      displayOrder: 2
    }, {
      label: 'Email',
      key: 'email',
      required: true,
      displayOrder: 3,
      type: 'string'
    }, {
      label: 'Password',
      key: 'password',
      required: true,
      displayOrder: 4,
      type: 'password'
    }
  ]
};

export default withAuthenticator(Admin, { signUpConfig, usernameAttributes: 'email' });
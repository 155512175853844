import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Index from './containers/Index'
import Download from './containers/Download'
import Contact from './containers/Contact'
import Profile from './containers/Profile'
import Admin from './containers/Admin'
import './App.css';

const App = () => (
    <main className="main">
      <Switch>
        <Route exact path='/' component={Index}/>
        <Route path='/contact' component={Contact}/>
        <Route path='/profile' component={Profile}/>
        <Route path='/download' component={Download}/>
        <Route path='/admin' component={Admin}/>
      </Switch>
    </main>
  )

  export default App
